import React, { useEffect, useState } from "react";
import { userAction } from "../../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import one from "../../assets/icons/user.png";
import two from "../../assets/profileBG.png";
import { useNavigate } from "react-router-dom";
import { change } from "../../redux/lang"
import { Dropdown } from "antd";
import customProtocolCheck from "custom-protocol-check";
import Description from "../Adons/Description";
import three from "../../assets/gold.svg";
import four from "../../assets/dimond.svg";
import five from "../../assets/bronze.svg";
import useChangeUserData from "../../customHooks/useChangeUserName";


export default function Profile() {
  const [userProfile, setuserProfile] = useState(null);
  const [username, setUserName] = useState("");
  const auth = useSelector((state) => state.user);
  const language = useSelector((state) => state.lang.language);
  const router = useNavigate();
  const [changeProfile, setChangeProfile] = React.useState(false);
  const dispatch = useDispatch();
  const userLogout = () => {
    dispatch(userAction.logout());
  };
  const {  changeUserNameOnline, loading, usernames } = useChangeUserData()

  const changeUsers = (name) => {
    console.log("find", name.target.value)
    setUserName(name.target.value)
  }


  const changeLanguage = (lang) => {
    console.log(lang)
    dispatch(change(lang));
  };

  React.useEffect(() => {
    let authParser = auth.value;
    setuserProfile(authParser);
  }, []);

  const newDataIs = (link) => {
    customProtocolCheck(
      link,
      () => {
        console.log("Custom protocol not found.");
      },
      () => {
        console.log("Custom protocol found and opened the file successfully.");
      },
      5000
    );
  };
  const items = [
    {
      key: 1,
      value: "am",
      label: <a onClick={() => changeLanguage("am")}>Amharic</a>,
    },
    {
      key: 2,
      value: "en",
      label: <a onClick={() => changeLanguage("en")}>English</a>,
    },
    {
      key: 3,
      value: "or",
      label: <a onClick={() => changeLanguage("or")}>Oromo</a>,
    },
    {
      key: 4,
      value: "af",
      label: <a onClick={() => changeLanguage("af")}>Afar</a>,
    },
    {
      key: 5,
      value: "ti",
      label: <a onClick={() => changeLanguage("ti")}>Tigrinya</a>,
    },
    {
      key: 6,
      value: "so",
      label: <a onClick={() => changeLanguage("so")}>Somali</a>,
    },
  ];
  let togleProfileName = () =>{
    let user = auth?.value?.phone
    setUserName()
    changeUserNameOnline(user, username) 
    setChangeProfile(!changeProfile)
  }
  useEffect(() => {
    // if (changeProfile == false && username != '') {
      changeUserNameOnline(auth.phone, username) 

      if (usernames != ''){
        setUserName(usernames)
      }
    // }
  }, [changeProfile, usernames])


  if (userProfile) {
    return (
      <div className="bg-[white] pb-5 md:pb-0">
        <div
          className="w-[100%] flex flex-col justify-center h-[200px] bg-cover "
          style={{
            backgroundImage: `url(${two})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className=" w-full flex flex-col justify-center items-center">
            <img width={50} height={50} alt="face" src={one} />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center px-0 pt-20 bg-[#242C37]">
          <div className="flex flex-col border-b-[1px] border-gray-500 pb-5">
            <h1 className="text-white pt-2 text-center">
              {userProfile?.isSubscribed ? (
                userProfile?.phone
              ) : (
                <a
                  className="font-milkb"
                  onClick={() =>
                    newDataIs(`sms:${9871}?body=${encodeURIComponent("ok")}`)
                  }
                >
                  Subscribe{ }
                </a>
              )}
            </h1>
            <a
              onClick={() => togleProfileName()}
              className="text-xs text-gray-500 text-center font-milkr"
            >
              {!changeProfile ?  language?.changeUN : "save username"}
            </a>
            <div>
              <input
                prefix="@kinet-"
                onChange={(e) => changeUsers(e)}
                value={ username}
                className={` ${changeProfile
                    ? "visible font-milkb my-10 px-10 py-2 rounded-sm outline-none text-center text-black transition-all ease-in-out delay-200 duration-300"
                    : "invisible transition-all ease-in-out delay-150 duration-300 text-center text-white font-milkb"
                  }`}
                placeholder="Gamer Name"
              />
            </div>
          </div>
          <div className="mt-5">
            <p className="text-sm text-white font-milkr text-center px-10 ">{language?.privecy}</p>
            {/* <span className="text-xs text-gray-500">Tap here to edit bio</span> */}
          </div>
          <div className="w-[93%] justify-center items-center flex flex-col bg-[#ffffff00] mt-10">
            <h1 className="text-center text-[white] font-milkb">
              {language?.getCoin}
            </h1>
            <p className="text-white text-center font-roboto">
              {language?.nobadge}
            </p>
            <div className="flex flex-col  justify-center  items-center w-[80%] min-h-[20vh] bg-white rounded-lg my-5">
              <h1 className="text-[gray] text-2xl border-b-[4px] mb-4 font-milkb ">
                Available Coins
              </h1>
              <div className="flex flex-col md:flex-row w-[100%] md:h-[20vh] md:justify-center items-center">
                {[
                  {
                    name: "coin name",
                    description: "coin description and offers",
                    coinImage: three,
                    price: "20 birr",
                    number: 200,
                  },
                  {
                    name: "coin name",
                    description: "coin description and offers",
                    coinImage: four,
                    price: "20 birr",
                    number: 180,
                  },
                  {
                    name: "coin name",
                    description: "coin description and offers",
                    coinImage: five,
                    price: "20 birr",
                    number: 100,
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    // onClick={() => getDeviceInfo()}
                    className="bg-[#242C37] w-[90%] mb-2 md:w-32 h-32 cursor-pointer  rounded-md  shadow-xl flex flex-col justify-center items-center mx-5 "
                  >
                    {/* <Image alt="coin" width={50} height={50} src={item.coinImage} /> */}
                    <img alt="coin" src={item.coinImage} className="w-20 h-10" />
                    <h1 className="text-xs text-white font-milkb">{item.number} Coins</h1>
                    <h1 className="uppercase text-sm font-bold font-milkb text-[#FF9900]">
                      {item.price}
                    </h1>
                  </div>
                ))}
              </div>
              <h1 className="text-[gray] text-2xl border-b-[4px] mb-4 font-milkr ">
                My Store
              </h1>
              {false ? (
                <div></div>
              ) : (
                <div>
                  {" "}
                  <h1 className="text-[#242c37e0] py-5 font-milkr">You Have Not Purchased any coin</h1>
                </div>
              )}
            </div>
          </div>
          <div className="md:w-[20%] pb-20">
            {[
              {
                name: "Language",
                value: (
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                    className="p-1 bg-[#242C37] ml-5"
                    arrow
                  >
                    <a className="w-[60px] text-sm  text-[white] cursor-pointer md:text-sm text-center self-center  !border-[1px] rounded-sm font-milkr">
                      {language?.lang}
                    </a>
                  </Dropdown>
                ),
                route: "/privecy",
                component: (
                  <a className="font-milkb">{language?.languageName}</a>
                ),
              },
              {
                name: "privacy policy",
                value: "",
                route: "/privecy",
                component: (
                  <h1
                    className="cursor-pointer font-milkb"
                    onClick={() => router.push("/home/privacy/")}
                  >
                    {language?.privecyPolicy}
                  </h1>
                ),
              },
              {
                name: "Logout",
                value: "",
                route: "#",
                component: (
                  <a
                    className="cursor-pointer font-milkb"
                    onClick={() => userLogout()}
                  >
                    {language?.logout}
                  </a>
                ),
              },
            ].map((item, index) => (
              <div
                key={index}
                className="w-full text-white flex flex-row justify-center border-b-[1px] border-b-gray-400 mt-5 mb-5 pb-2 "
              >
                {item.component}
                <a className="text-[#FE9902] text-xs cursor-pointer">
                  {item.value}
                </a>
              </div>
            ))}
          </div>
        </div>
        <Description />
        {/* <br/> */}
      </div>
    );
  } else {
    return (
      <div>
        <h1>Loading</h1>
      </div>
    );
  }
}
