import React, { useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { notification} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  login
} from "../../redux/auth";
import { IconsLoader } from "../../imports/icon";
import { useEnvironment } from "../../context/EnvironmentContext";

// New Functions
import { useEventsListenersGTAG, newDataIs } from "./customHooks/useUtility";
import useSignInHook from "./customHooks/useSigninHook";
import Loading from "./components/Loading";


export default function PhoneScreen() {
  const [value, setValue] = React.useState("");
  const [api, contextHolder] = notification.useNotification();
  const { isWebViewApp } = useEnvironment();
  // New Handler
  let { userAuthentication, isSubscribed, loading, userObj } = useSignInHook()
  const dispatch = useDispatch();
  const language = useSelector((state) => state.lang.language);


  const openNotificationWithIcon = (type, title, body, phone) => {
    api[type]({
      message: title,
      description: body,
    });
  };

  const signInPhone = async (phone) => {
    if (!phone) {
      phone = ''
    }
    if (isValidPhoneNumber(phone)) {
      useEventsListenersGTAG({
        action: 'Login Attempted',
        category: "Authentication",
        label: `${phone} Attempted to login`,
        value: phone
      })
      let user = await userAuthentication(phone)
      if (user?.isSubscribed) {

        useEventsListenersGTAG({
          action: 'Subscribed Login Attempted',
          category: "Authentication",
          label: `${phone} Attempted to login`,
          value: phone
        })

        openNotificationWithIcon(
          "success",
          "Welcome back",
          `${phone} is subscribed to kinet store`
        );

        // Handle User Info On Redux
        let response = await dispatch(login(user.news));
        console.log(response)
      }
      else {
        useEventsListenersGTAG({
          action: 'New User Login Attempted',
          category: "Authentication",
          label: `${value} Attempted to login`,
          value: value
        })

        openNotificationWithIcon(
          "info",
          "Please Subscribe to gain access or explore other options with our telegram bot",
          `${value} is currently not  subscribed to kinet store`
        );
      }

    } else {
      console.log("got it", phone)
      console.log((isValidPhoneNumber(phone, 'ET')))
    }
    // Primery Validation 
  };


  useEffect(() => {
    if (isWebViewApp) {
      console.log("i am now here", window.parent)
    }
  }, [])

      return (
        <div className="flex flex-col  justify-center w-[100%]   bg-[#242C37] rounded-md">
          {contextHolder}
          <div className="px-0 flex flex-col  h-[100%] pt-8  justify-left w-[100%] mr-10">
            <h1 className="text-[3vw] text-white font-milkb">
              {language.welcome1}
            </h1>
            <h5 className="capitalize text-lg xl:text-[1vw] xl:mb-[1vh] py-2 mt-2 font-milkr text-white">
              {language.userorphone}
            </h5>
            <PhoneInput
              className="bg-[#fcfcfc] border-[1px] border-[#3B3B3B] text-[black] px-2 py-2 xl:text-[1vw] rounded-md "
              placeholder={language.enterphonenumber}
              defaultCountry="ET"
              valid
              color="white"
              value={value}
              onChange={(e) => signInPhone(e)}
            />
            <a href="https://t.me/kinetGamesBot" target="_blank" className="text-right py-3 xl:text-[1vw] xl:py-[2vh]   hover:text-[#FE9902] text-[white] font-milkr text-xs">
              {language.telegramLogin}
            </a>
          </div>
          <a
            disabled={loading}
            href="sms:9871?body=ok" 
            className="flex flex-col justify-center xl:h-[5vh] align-bottom items-center shadow-lg z-0 bg-transparent"
          >
            <div className="w-[100%] max-h-[50px]  bg-gradient-to-r py-[0px] xl:py-[2vh]  from-[#FE9902] via-[#FE9902] to-[#FE9902] shadow-xl rounded-[5px] overflow-hidden">
              <div className="flex flex-row justify-end px-5">
                <IconsLoader icon="playmask" />
              </div>
            </div>

            <h1 className="absolute xl:text-[1vw]  text-[white] font-milkr text-md uppercase">
              {!loading ? language.subscribe : <Loading />}
            </h1>
          </a>
        </div>
      );
    }

