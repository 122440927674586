import { useState, useCallback } from "react";

const useSignInHook = () => {
    const [loading, setLoading] = useState(false);

    // Check if user is subscribed
    const checkIfSubscribed = async ({ phone }) => {
        try {
            const response = await fetch(
                'https://us-central1-kinet-web.cloudfunctions.net/checkIfSubscribed',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ phone }),
                }
            );

            const data = await response.json();
            return data.isSubscribed && false
        } catch (e) {
            return false
        } finally {
            return false
        }
    };


    const userAuthentication = async (phone) => {
        setLoading(true)
        // if user is not subscribed when he/she enters his/her phone will be returning a status
        if (!checkIfSubscribed(phone)) {
            setLoading(false)
            return {isSubscribed: false, user: null}
        }
        try {
            const response = await fetch(
                "https://us-central1-kinet-web.cloudfunctions.net/loginUserWithFirestore",
                {
                    method: "POST", // or 'PUT'
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ phone: phone }),
                }
            );
            const res = await response.json();
            console.log("user", res)
            setLoading(false)
            return res
        } catch (e) {
            setLoading(false);
            return {isSubscribed: false, user: null}
        }
    }; // Dependency array


    return { userAuthentication,  loading }
};

export default useSignInHook;
