import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../auth";
// import sysReducer, {sysTournament, sysGames, sysInTournament, sysAllTour} from '../sys'
import locReducer, {change} from '../lang'
// import studentReducer from "../student";
// import coursesReducer, { listCourse } from "../courses";
import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import { setupListeners } from "@reduxjs/toolkit/query";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";


const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};
 
const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();


const persistConfig = {
  key: "user",
  storage: storage,
};

const persistConfigsys = {
  key: "sys",
  storage: storage,
};

const persistConfiglang = {
  key: "lang",
  storage: storage,
};

const store = configureStore({
  reducer: {
    user: persistReducer(persistConfig, userReducer),
    // sys: persistReducer(persistConfigsys, sysReducer),
    lang: persistReducer(persistConfiglang, locReducer)
    // student: studentReducer,
    // courses: coursesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// store.dispatch(sysTournament());
// store.dispatch(sysAllTour())
// store.dispatch(sysInTournament());
store.dispatch(change('en'))
setupListeners(store.dispatch);

export default store;