import React, { useEffect, useState } from 'react'
import './App.css';
import {
  RouterProvider,
} from "react-router-dom";
import { router } from './Navigation/router';
import { ErrorBoundary } from "react-error-boundary";
import { EnvironmentProvider } from './context/EnvironmentContext';




function App() {

  return (
    <EnvironmentProvider>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </EnvironmentProvider>

  );
}

export default App;
