import { useState } from 'react';

const useSaveHighScore = () => {
  const [errorsaving, setError] = useState(null);
  const [loadingsaveing, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const saveHighScore = async (phone, gamename, level, score, withHistory) => {
    setLoading(true);
    setError(null);
    try {
      const res = await fetch(
        'https://us-central1-kinet-web.cloudfunctions.net/saveGameDataForUsers',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            gamename,
            phone,
            life: 10,
            level: level || 1,
            score: score || 1,
            history: [],
            with_history: withHistory,
          }),
        }
      );

      const data = await res.json();
      console.log(data)
      setResponse(data);
      
      if (data.status === 405) {
        setError(data.status);
      }
      
      return data;
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { saveHighScore, errorsaving, loadingsaveing, response };
};

export default useSaveHighScore;
