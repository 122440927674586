import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const API_URL = "http://192.168.172.79:8000/api/";
export const APIS_STATIC = "http://192.168.172.79:8000";

export const strings = {
  en: {
    lang: "en",
    languageName: 'Language',
    tournament: 'Tournaments',
    games: 'Games',
    privecyPolicy: 'privacy policy',
    logout: 'Logout',
    singIn: "Sign In",
    playNow: "Play Now",
    heroText:
      "By entering this site you confirm that you accept our terms and service and have read our privacy policy",
    activeTournament: "Active Tournaments",
    seeall: "See All",
    welcome1: "Welcome Back",
    userorphone: "Phone Number",
    enterphonenumber: "Enter phone number",
    persist: "keep me logged in",
    login: "Login",
    signup: "Sign Up",
    changeUN: "Tap here to change user name",
    privecy:
      "By entering this site you confirm that you accept our terms and service and have read our privacy policy",
    badge: "Earned Badges",
    nobadge: "You Have Not Purchased Any Coin",
    profile: 'Profile',
    winPrize: 'PLAY AND WIN PRIZES',
    winDescribe: 'Join thrilling tournaments and unleash your full potential to crush high scores that stand between you and a treasure trove of prizes.',
    joinTrou: 'Join Tournament',
    noPrize: 'No Prize Available',
    rules: 'send "ok" to 9871 to subscribe.there is a 3-day free trial period, followed by service cost of 2 birr per day.',
    gameHeader: 'Play Our Fun And Popular Games!',
    contactus: 'Contact Us',
    privecyPolicy: 'Privacy Policy',
    termandservice: 'Terms and service',
    gamesTitle: 'Experience instant joy with our fast-paced, simple games! ',
    leaderBoard: 'Leader Board',
    noscore: 'No High Score so far',
    autoplay: 'Starting Game',
    upcommingtour: 'Upcoming Tournaments',
    joinlegend: 'join and become a living legend, the arena awaits!',
    mainLogin: 'send "ok" to 9871 to subscribe!',
    download: 'Download the Kinet App',
    play: 'Play',
    playNowHome: "Play",
    highscore: 'High Score',
    otpValidate: "Verify OTP",
    getCoin: 'Get Coin',
    subscirbeToPlayMore: 'Three Days Free Trial',
    subscribePlease: 'Please Subscribe To Continue',
    sendingMessage: 'We are sending OTP Code to the provided phone number. Please do not share this code with others for security reasons.',
    subscribe: 'Subscribe',
    telegramLogin: "Or Play Kinet Games With Telegram"
  },
  am: {
    lang: "am",
    languageName: 'ቋንቋ',
    games: 'ጨዋታ',
    privecyPolicy: 'የግላዊነት ፖሊሲ',
    tournament: 'ውድድር',
    logout: 'መውጣት',
    singIn: "ይግቡ",
    playNow: "አሁን ይጫወቱ",
    heroText:
      "ወደዚህ ጣቢያ በመግባት ውላችንን እና አገልግሎታችንን እንደተቀበሉ እና የግላዊነት መመሪያችንን እንዳነበቡ ያረጋግጣሉ",
    activeTournament: "በመካሄድ ላይ ያሉ ውድድሮች",
    seeall: "ሁሉንም ይዩ",
    welcome1: "እንኳን ደህና መጡ!",
    userorphone: "ስልክ ቁጥር",
    enterphonenumber: "ስልክ ቁጥር ያስገቡ",
    persist: "መለያዬን እንደገባ አቆይ",
    login: "ይግቡ",
    signup: "ይመዝገቡ",
    changeUN: "የተጠቃሚ ስም ለመቀየር እዚህ ይንኩ።",
    privecy:
      "ወደዚህ ጣቢያ በመግባት ውላችንን እና አገልግሎታችንን እንደተቀበሉ እና የግላዊነት መመሪያችንን እንዳነበቡ ያረጋግጣሉ",
    badge: "የተገኙ ባጆች",
    nobadge: "እስካሁን ምንም ባጅ አላገኙም።",
    profile: 'ገጽታ',
    winPrize: 'ይጫወቱ እና ሽልማቶችን ያሸንፉ',
    winDescribe: 'አስደሳች ውድድሮችን ይቀላቀሉ:: በእርስዎ እና በሽልማቶች መካከል ያለውን ክፍተት ያጥቡ።',
    joinTrou: 'ውድድሩን ይቀላቀሉ',
    noPrize: 'ምንም ሽልማት የለም።',
    rules: '"ok" ብለው ወደ 9871 በመላክ ሰብስክራይብ ያድርጉ።የ 3 ቀን ነፃ የሙከራ ጊዜ አሎት ከዚያም የአገልግሎት ዋጋ በቀን 2 ብር ይሆናል',
    gameHeader: 'አዝናኝ እና ተወዳጅ ጨዋታ ይጫወቱ!',
    contactus: 'አግኙን',
    privecyPolicy: 'የግላዊነት ፖሊሲዎች',
    termandservice: 'ውሎች እና አገልግሎቶች',
    gamesTitle: 'በፈጣን እና ቀላል ጨዋታዎች የእረፍት ጊዜዎን በደስታ  ያሳልፉ !',
    leaderBoard: 'የውጤት ሰሌዳ',
    noscore: 'እስካሁን ምንም ከፍተኛ ነጥብ የለም።',
    autoplay: 'ጨዋታውን በመጀመር ላይ',
    upcommingtour: 'መጪ ውድድሮች',
    joinlegend: 'ይቀላቀሉ እና ህያው አፈ ታሪክ ይሁኑ ፣ መድረኩ ይጠብቃል!',
    mainLogin: '"ok" ብለው ወደ 9871 በመላክ ሰብስክራይብ ያድርጉ::',
    download: 'የ Kinet መተግበሪያን ያውርዱ',
    play: 'ይጫወቱ',
    playNowHome: "ይጫወቱ",
    highscore: 'ከፍተኛ ነጥብ',
    otpValidate: "ኦቲፒን ያረጋግጡ",
    getCoin: 'Get Coin',
    subscirbeToPlayMore: 'ለ3 ቀን በነጻ የሞከሩ',
    subscribePlease: 'ለመቀጠል እባክዎን ሰብስክራይብ ያድርጉ',
    sendingMessage: 'We are sending OTP Code to the provided phone number. Please do not share this code with others for security reasons.',
    kinetStore: 'Kinet Store Authentication',
    subscribe: 'ሰብስክራይብ ያድርጉ',
    telegramLogin: "Or Play Kinet Games With Telegram"



  },
  af: {
    lang: "af",
    languageName: 'afa',
    games: 'canka diinisso',
    privecyPolicy: 'qalayli',
    tournament: 'digiri',
    logout: 'mayuuqu',
    singIn: "culma",
    playNow: "away digira",
    heroText:
      "afanteyna fan culaanam kee ittin gey ayfaafitte akah hirgtan ina kee canka diinisso akah takriyen inna kinni.",
    activeTournament: "gersitak geytimta anaakarwa.",
    seeall: "inkkih ubula",
    welcome1: "unkkaq muquk temeeteenim!",
    userorphone: "silki loowo",
    enterphonenumber: "silki loowo culusa",
    persist: "badi kood akah baxsimannah suga.",
    login: "cula",
    signup: "antukuba",
    changeUN: "edde yantifiqeme nibro milaagimtuh akke dag",
    privecy:
      "afanteyna fan cultoonuh ittin gey ayfaafitte akah hirgtan innah cankka  innah  diinisso akah takriye innah ismita.",
    badge: "geytimteh tan abtoota",
    nobadge: "ahaydumah uli baaj mageytiminna",
    profile: 'weelo',
    winPrize: 'digiray maysso acwa beya',
    winDescribe: 'rufto le tan anaakarwat engela koo kee acwoowi fanat yanih yan cooca daggoyssa.',
    joinTrou: 'anaakarat engela',
    noPrize: 'tuh acwa matana',
    rules: '"ok" ብለው ወደ 9871 በመላክ ሰብስክራይብ ያድርጉ።የ 3 ቀን ነፃ የሙከራ ጊዜ አሎት ከዚያም የአገልግሎት ዋጋ በቀን 2 ብር ይሆናል',
    gameHeader: 'rufto kee wacaysir le digrwa wagaasita.',
    contactus: 'neegeya',
    privecyPolicy: 'cankiini diinisoosa',
    termandservice: 'asenta kee ayfaafitte',
    gamesTitle: 'dabcin dirway sissi waycaysir aroocal wagaasita',
    leaderBoard: 'xaloot luucu',
    noscore: 'aham fan fayya le dimmo mali',
    autoplay: 'digir qebisak geytima',
    upcommingtour: 'tamaatu wayta qalayli',
    joinlegend: 'engelay liton niya afti aydaadu handdag yakkay qambaala!',
    mainLogin: '"ok" arcuk tama nibro fan 9871 ruubak sabskiraybi aba',
    download: 'Kinet abniso ibsita',
    play: 'digira',
    playNowHome: "digrita",
    highscore: 'fayyale dimmo',
    otpValidate: "otpi ismita",
    getCoin: 'Get Coin',
    subscirbeToPlayMore: '3 ayro gidi curri abta',
    subscribePlease: 'siinicigiiluh sabscariybi aba',
    sendingMessage: 'We are sending OTP Code to the provided phone number. Please do not share this code with others for security reasons.',
    kinetStore: 'Kinet Store Authentication',
    subscribe: 'sabscariybi',
    telegramLogin: "Or Play Kinet Games With Telegram"




  },
  or: {
    lang: "or",
    languageName: 'Afaan',
    games: 'Tapha',
    privecyPolicy: 'Taphoota',
    tournament: 'Imaammata dhuunfaa',
    logout: 'Bahuu',
    singIn: "Seenaa",
    playNow: "Amma Taphadhaa",
    heroText:
      "Gara saayitii kanaa seenudhaan waliigaltee fi tajaajila keenya akka fudhattanii fi qajeelfamoota dhuunfaa akka dubbisan mirakneessaa",
    activeTournament: "Dorgommiiwwan adeemsifamaa jiran",
    seeall: "Hunda ilaalaa",
    welcome1: "Baga Nagaan Dhuftan!",
    userorphone: "Lakkoofsa Bilbilaa",
    enterphonenumber: "Lakkoofsa bilbilaa galchaa",
    persist: "Akkuma seentaniin turaa",
    login: "Seenaa",
    signup: "Galmaa'aa",
    changeUN: "Maqaa fayyadamaa jijjiiruuf as tuqaa",
    privecy:
      "Gara saayitii kanaa seenudhaan waliigaltee fi tajaajila keenya akka fudhattanii fi qajeelfamoota dhuunfaa akka dubbisan mirakneessaa",
    badge: "Baajiwwan argaman",
    nobadge: "Hanga ammaatti baajii hin arganne",
    profile: 'Piroofaayilii',
    winPrize: "Taphachuudhaan badhaasa moo'adhaa'",
    winDescribe: "Taphawwan baay'ee gammachiisoo seenudhaan dandeettii qabxii isinii fi badhaasa gidduu jiru cabsaa",
    joinTrou: 'Tapha seenaa',
    noPrize: 'badhaasni hin jiru',
    rules: "'OK' gara 9871 tti erguudhaan galmaa'aa. Yeroo yaalii guyyaa 3 tu jira, sana booda kaffaltii tajaajilaa guyyaatti qarshii 2tu herreegama.",
    gameHeader: 'Taphoota bohaarsoo fi jaalatamoo taphadhaa',
    contactus: 'Nu argadhaa',
    privecyPolicy: 'Imaammata dhuunfaa',
    termandservice: 'Dambii fi tajaajila',
    gamesTitle: "Taphawwan ariifatoo fi salphaa ta'aniin yeroo boqonnaa keessan gammachuun dabarsaa!",
    leaderBoard: "Gabatee bu'aa",
    noscore: 'Hanga ammaatti qabxii olaanaan hin jiru',
    autoplay: 'Taphni jalqabaa jira',
    upcommingtour: 'Dorgommiiwwan dhufaa jiran',
    joinlegend: "seenudhaan goota jiraataa ta'aa, waltajjiin isin eega",
    mainLogin: "Galmaa'udhaaf 'ok' gara 9871 ergaa",
    download: 'Moosaajii kinet buufadhaa',
    play: 'Taphadhaa',
    playNowHome: "Taphadhaa",
    highscore: 'Qabxii Olaanaa',
    otpValidate: "OTP mirkaneessaa",
    getCoin: 'Qabxii argadhaa',
    subscirbeToPlayMore: 'Yaalii tolaa guyyaa sadii',
    subscribePlease: "Itti fufuuf galmaa'aa",
    sendingMessage: 'We are sending OTP Code to the provided phone number. Please do not share this code with others for security reasons.',
    kinetStore: 'Kinet Store Authentication',
    subscribe: "galmaa'aa",
    telegramLogin: "Or Play Kinet Games With Telegram"




  },
  ti: {
    lang: "ti",
    languageName: 'ቐንቓ',
    games: 'ጸዋታ',
    privecyPolicy: 'ናይ ውልቀ ፖሊሲ',
    tournament: 'መልዓሊ',
    logout: 'ውጻእ',
    singIn: "እተው",
    playNow: "ሓዚ ነካእ",
    heroText:
      "በዚ ኣቢለካ እንተኣቲ ናትና ተገላጋልን ተጠቃምን  ምካንካ ክተረጋግጽትክእል ኢካ",
    activeTournament: "ፈጣን ጻወታ",
    seeall: "ኩሎም ረኣም",
    welcome1: "እናካዕ ዳሓን መጻ እኩም",
    userorphone: "ስልኪ ቁጽሪ",
    enterphonenumber: "ኣእትው ስልኪ ቁጽሪ",
    persist: "መፍለያካ ተከታተል",
    login: "እተው",
    signup: "ተመዝገብ",
    changeUN: "ስም ተጠቃሚ ንምቕይር ኣበዚ ንካእ።",
    privecy:
      "በዚ ኣቢለካ እንተኣቲ ናትና ተገላጋልን ተጠቃምን  ምካንካ ክተረጋግጽትክእል ኢካ",
    badge: "በጅ ርከብ",
    nobadge: "ምንም ትገዝኦ ነገር የለን።",
    profile: 'ማህደር',
    winPrize: 'ተጻወት ተዓወት',
    winDescribe: 'ኣብ ብተደጋጋሚ ብምልምማድ ዝለዓለ ወጽኢት ዘለዎ ናይ ጽዋታ ተሸላሚክትከውን ትክእል ኢካ',
    joinTrou: 'ኣቢዚ ጸወታ ተሓወስ',
    noPrize: 'ተውጸኦ ውጻኢ የብልካን።',
    rules: '"ok" ኢልካ ናብ 9871 ብመልኣክ ሳብስክራይ ግበሩ ና 3 ናይ ነጻ ትረክብ',
    gameHeader: 'መዘናግዓን ተፈታውን ጸወታና ተጻወት',
    contactus: 'ርከቡና',
    privecyPolicy: 'ናይ ውልቀ ፖሊሲ',
    termandservice: 'ቀለትን ግልጋሎታትን',
    gamesTitle: 'ፈጣንን ቀላልን ጸወታና ኣብ እረፍትኩሙ ተዝናነይሉ',
    leaderBoard: 'ናይ ውጽኢት ሳንዱቕ',
    noscore: 'ክሳብ ሐዚ እዚ ዝበሃል ልዑል ውጽኢት የለን።',
    autoplay: 'ኣብ ምጅማርጸወታ',
    upcommingtour: 'ዝመጹማድድራጽ',
    joinlegend: 'ተሐወስ ኣብ ጽዋታና ባዓል ታሪክ ኩን',
    mainLogin: 'ናብ 9871 "ok" ኢልካ ስደድ::',
    download: 'ናይ Kinet መተግበሪ ኣውርድ',
    play: 'ተጻወት ተዓወት',
    playNowHome: "ተጻወት ተዓወት",
    highscore: 'ልዑል ውጽኢት',
    otpValidate: "ኦቲፒ ኣብርህ",
    getCoin: 'ርከብ',
    subscirbeToPlayMore: '3መዓልቲ ናይ ሙከራ ጊዝ',
    subscribePlease: 'ንምቅጽል ሳብስካርብ ግበሩ',
    sendingMessage: 'We are sending OTP Code to the provided phone number. Please do not share this code with others for security reasons.',
    kinetStore: 'Kinet Store Authentication',
    subscribe: "ሳብስካርብ ግበሩ",
    telegramLogin: "Or Play Kinet Games With Telegram"




  },
  so: {
    lang: "so",
    languageName: 'Luuqad',
    games: 'Ciyaaro',
    privecyPolicy: 'Siyaasadda arrimaha shakhsiyeed',
    tournament: 'ውድድር',
    logout: 'Ka-bax',
    singIn: "Soo-galid",
    playNow: "Imika ciyaar",
    heroText:
      "Galitaanka meeshan waxaad ku-xaqiijinaysaa in aad oggolaatay xeerkayaga iyo adeeggayaga ood akhrisatay siyaasaddayada arrimaha shakhsiyeed policy",
    activeTournament: "Tartano socda",
    seeall: "eegDhammaan",
    welcome1: "Markale soo-dhawow",
    userorphone: "Lambarka Telefoonka",
    enterphonenumber: "gali lambarka telefoonka",
    persist: "Ku-jiritaanka ii sii wad",
    login: "Gal",
    signup: "Ku-soo-biir",
    changeUN: "meeshan taabo si aad u baddashid magaca isticmaalaha",
    privecy:
      "Galitaanka meeshan waxaad ku-xaqiijinaysaa in aad oggolaatay xeerarkayga iyo adeeggayaga ood akhrisatay siyaasaddayada arrimaha shakhsiyeed",
    badge: "Baajajka la-helay",
    nobadge: "Maanad iibsan wax qaddaadiic ah",
    profile: 'muuqaalka',
    winPrize: 'Ciyaar oo abaalmarinno ku-guulayso',
    winDescribe: 'Ku biir tartamada xiisaha leh oo si buuxda u soo saar awoodaada si aad u burburiso buundooyinka sare ee idiin dhexeeya adiga iyo abaalmarinada lacageed ee qaaliga ah..',
    joinTrou: 'Tartanka ku-biir',
    noPrize: 'abaal-marin ma-jirto',
    rules: '"ok"  u dir 9871 oo is-diiwaangali. Waxaa jirta muddo 3-maalmood oo tijaabo bilaa lacag ah, ka-dibna maalintiiba waxaad bixinaysaa 2 birr oo adeegga ah.',
    gameHeader: 'Ciyaar Ciyaarahayaga Xiisaha Badan ee Caanka ah!',
    contactus: 'Nala-soo-xidhiidh',
    privecyPolicy: 'siyaasadda arrimaha shakhsiyeed',
    termandservice: 'xeerarka iyo adeegga',
    gamesTitle: 'Farxad degdeg ah ka-hel ciyaarahayga xawliga ku-socoda ee fudud!',
    leaderBoard: 'Boodhka hoggaamiyaha',
    noscore: 'wali dhibic sare ma-jirto',
    autoplay: 'bilaabid ciyaar',
    upcommingtour: 'tartanada soo-socda',
    joinlegend: 'ku biir oo noqo halyeey nool, saaxadda ayaa sugaysa!',
    mainLogin: '"ok" u dir 9871 oo is-diiwaangali.',
    download: 'Soo-dajiso app-ka "kinet"',
    play: 'ciyaar',
    playNowHome: "ciyaar",
    highscore: 'dhibic sare',
    otpValidate: "xaqiiji otp",
    getCoin: '"coin" hel',
    subscirbeToPlayMore: 'saddex maalmood oo tijaabo bilaa lacag ah',
    subscribePlease: 'fadlan is-diiwaangali si aad u sii waddid',
    sendingMessage: 'We are sending OTP Code to the provided phone number. Please do not share this code with others for security reasons.',
    kinetStore: 'Kinet Store Authentication',
    subscribe: "Subscribe",
    telegramLogin: "Or Play Kinet Games With Telegram",





  },
};


export const locSlice = createSlice({
  name: "language",
  initialState: {
    language: strings.en,
  },
  reducers: {
    change: (state, action) => {
      switch (action.payload) {
        case "en":
          state.language = strings.en;
          console.log("English");
          break;
        case "am":
          state.language = strings.am;
          console.log("Amharic");
          break;
        case "af":
          state.language = strings.af;
          console.log("Afar");
          break;
        case "or":
          state.language = strings.or;
          console.log("Oromo");
          break;
        case "ti":
          state.language = strings.ti;
          console.log("Tigrinya");
          break;
        case "so":
          state.language = strings.so;
          console.log("Somali");
          break;
        default:
          state.language = strings.en;
          console.log("Unknown language");
      }
    },
  },
});

export const { change } = locSlice.actions;
export default locSlice.reducer;
